import React, { Component } from "react";
import Context from "./Context";
import axios from "axios";


const API_URL = process.env.REACT_APP_API_URL;
const DEFAULT_QUOTE_ID = process.env.REACT_APP_DEFAULT_QUOTE_ID;

class Provider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            jwtToken: null,
            project: null,
            formData: null,
            totalPrice: 0.0,
            fileToTranslate: null,
            notaryFileToTranslate: null,
            disableOrderCards: false,
            showErrorModal: false,
            disableFileUpload: false,
            disableTextarea: false,
            insertedText: '',
            finalInsertedText: '',
            insertedTextFileName: '',
            baseLanguage: '',
            targetLanguage: '',
            baseLanguageGeo: '',
            targetLanguageGeo: '',
            documentType: "1",
            priceListId: DEFAULT_QUOTE_ID,
            documentTypeName: "სტანდარტული",
            additionalService: false,
            courierService: false,
            passingDate: 'slow',
            passingDateName: 'სტანდარტული',
            disableContinueBtn: true,
            orderTime: '',
            name: null,
            pageCount: null,
            email: null,
            phone: null,
            userVertificationCode: null,
            recievedVertificationCode: null,
            address: null,
            additionalAddress: null,
            city: null,
            comment: null,
            projectuid: null,
            prevousRequest: null,
            response: null,
            isProcessing: false,
            blogPosts: [],
        };
    }

    disableDocumentTypeHandler = () => {
        this.setState({ disableDocumentType: false, disableLanguages: true });
    };

    disableAdditionalServicesHandler = () => {
        this.setState({ disableAdditionalServices: false, disableDocumentType: true });
    };

    disablePassingDateHandler = () => {
        this.setState({ disablePassingDate: false, disableAdditionalServices: true });
    };

    disableOrderCardsHandler = () => {
        if (this.state.projectuid) {
            this.setState({ disableOrderCards: true });
        }
    };

    enableOrderCardsHandler = () => {
        this.setState({ disableOrderCards: false });
    };

    calculateNotaryPrice = async () => {

        await this.setState({ isProcessing: true });
        try {
            const apiUri = `${API_URL}/notary`;

            const { data } = await axios.post(apiUri, this.state.formData);
            // console.log(this.state.formData)
            // console.log("Notary Response :::  ", data);
            await this.setState({ response: data.response });
            await this.setState({ projectuid: data.response.projectuid });
        } catch (error) {
            this.showErrorModalHandler();
            // console.log(error)
        } finally {
            await this.setIsProcessing(false);
        }
    };


    calculatePrice = async () => {

        await this.setState({ isProcessing: true });

        if (!this.state.formData || !this.state.formData.has('fileToTranslate')) {
            await this.setState({ isProcessing: false });
            return;
        }

        const {
            finalInsertedText: _finalInsertedText,
            baseLanguage: _baseLanguage,
            targetLanguage: _targetLanguage,
            documentType: _documentType,
            additionalService: _additionalService,
            courierService: _courierService,
            city: _city,
            passingDate: _passingDate
        } = this.state;

        // console.log('START PRICE CALCULATION ... ');

        let requestData = {

            fileName: this.state.fileToTranslate || this.state.insertedTextFileName,
            sourceLang: _baseLanguage,
            targetLangs: _targetLanguage,
            documentType: _documentType,
            priceListId: this.state.priceListId,
            additionalService: _additionalService,
            courierService: _courierService,
            city: _city,
            passingDate: _passingDate,
            response: this.state.response
        };


        // console.log('REQUEST OBJECT : ', requestData);
        // console.log(' START PRICE CALCULATION ');
        // console.log('response:::',this.state.response)
        await this.setState({ projectuid: this.state.response.projectuid });
    };


    scrollToTop = () => {
        window.scrollTo(0, 0);
    };

    clearState = () => {
        this.setState({
            jwtToken: null,
            project: null,
            formData: null,
            totalPrice: 0.0,
            disableOrderCards: false,
            fileToTranslate: null,
            insertedText: '',
            finalInsertedText: '',
            insertedTextFileName: '',
            disableTextarea: false,
            baseLanguage: '',
            targetLanguage: '',
            baseLanguageGeo: '',
            targetLanguageGeo: '',
            documentType: "1",
            priceListId: DEFAULT_QUOTE_ID,
            documentTypeName: "სტანდარტული",
            additionalService: false,
            courierService: false,
            passingDate: 'slow',
            passingDateName: 'სტანდარტული',
            orderTime: '',
            disableContinueBtn: true,
            name: null,
            pageCount: null,
            email: null,
            phone: null,
            userVertificationCode: null,
            recievedVertificationCode: null,
            address: null,
            additionalAddress: null,
            city: null,
            comment: null,
            projectuid: null,
            notaryFileToTranslate: null,
            prevousRequest: null,

            response: {
                notary: false,
                projectuid: null,
                projectId: null,
                jobUid: null,
                jobId: null,
                totalPrice: 0.0
            },
            isProcessing: false
        });

    };

    clearStateOnOrderPage = () => {
        let savedPriceId = this.state.priceListId;
        this.setState({
            jwtToken: null,
            project: null,
            formData: null,
            totalPrice: 0.0,
            disableOrderCards: false,
            fileToTranslate: null,
            documentType: "1",
            priceListId: savedPriceId,
            projectuid: null,
            notaryFileToTranslate: null,
            insertedText: '',
            finalInsertedText: '',
            insertedTextFileName: '',
            documentTypeName: "სტანდარტული",
            additionalService: false,
            courierService: false,
            passingDate: 'slow',
            passingDateName: 'სტანდარტული',
            orderTime: '',
            disableContinueBtn: true,
            name: null,
            pageCount: null,
            email: null,
            phone: null,
            userVertificationCode: null,
            recievedVertificationCode: null,
            address: null,
            additionalAddress: null,
            // city:null,
            comment: null,
            prevousRequest: null,
            response: null,
            isProcessing: false

        });


    };

    clearFile = async () => {
        await this.setState({
            fileToTranslate: null,
            notaryFileToTranslate: null,
            formData: null,
        });

    };

    setResponse = async (_response) => {
        await this.setState({ response: _response });
    };

    setIsProcessing = async (value) => {
        await this.setState({ isProcessing: value });
    };

    setFormData = async (_formData) => {
        await this.setState({ formData: _formData });
    };

    setfileToTranslate = async (fileName) => {
        await this.setState({ fileToTranslate: fileName });
    };

    setNotaryFileToTranslate = async (fileName) => {
        await this.setState({ notaryFileToTranslate: fileName });
    };

    setInsertedText = e => {
        this.setState({ insertedText: e.target.value });
    };

    setDisableTextarea = (disable) => {
        this.setState({ disableTextarea: disable });
    };

    saveInsertedText = async () => {
        this.setIsProcessing(true);
        try {
            const apiUri = `${API_URL}/inserted`;
            let formData = new FormData();

            await this.setState({ finalInsertedText: this.state.insertedText });

            formData.set("baseLanguage", this.state.baseLanguage);
            formData.set("targetLanguage", this.state.targetLanguage);
            formData.set("additionalService", this.state.additionalService);
            formData.set("courierService", this.state.courierService);
            formData.set("city", this.state.city);
            formData.set("passingDate", this.state.passingDate);
            formData.set("documentType", this.state.documentType);
            formData.set("text", this.state.finalInsertedText);

            await this.setFormData(formData);
            let insertedResponse = await axios.post(apiUri, this.state.formData);
            // console.log("insertedResponse::::",insertedResponse)
            console.log(insertedResponse.data.response);
            await this.setState({ response: insertedResponse.data.response });
            await this.setState({ projectuid: insertedResponse.data.response.projectuid }
            );

        } catch (error) {
            this.setState({ showErrorModal: true });
            // console.log(error)

        } finally {
            this.setIsProcessing(false);
        }

    };


    setBaseLang = async e => {
        await this.setState({ baseLanguage: e.target.value });
        this.setBaseLangGeo();

        await this.calculatePrice();

    };

    setTargetLang = async e => {
        await this.setState({ targetLanguage: e.target.value });
        this.setTargetLangGeo();

        await this.calculatePrice();

    };

    setBaseLangGeo = () => {
        switch (this.state.baseLanguage) {
            case 'ka':
                this.setState({ baseLanguageGeo: 'ქართული' });
                break;
            case 'en':
                this.setState({ baseLanguageGeo: 'ინგლისური' });
                break;
            case 'ru':
                this.setState({ baseLanguageGeo: 'რუსული' });
                break;
            case 'tr':
                this.setState({ baseLanguageGeo: 'თურქული' });
                break;
            case 'es':
                this.setState({ baseLanguageGeo: 'ესპანური' });
                break;
            case 'fr':
                this.setState({ baseLanguageGeo: 'ფრანგული' });
                break;
            case 'ua':
                this.setState({ baseLanguageGeo: 'უკრაინული' });
                break;
            case 'de':
                this.setState({ baseLanguageGeo: 'გერმანული' });
                break;
            case 'zh':
                this.setState({ baseLanguageGeo: 'ჩინური' });
                break;
            case 'pt':
                this.setState({ baseLanguageGeo: 'პორტუგალიური' });
                break;
            default:
                ;
        }
    };
    setTargetLangGeo = () => {
        switch (this.state.targetLanguage) {
            case 'ka':
                this.setState({ targetLanguageGeo: 'ქართული' });
                break;
            case 'en':
                this.setState({ targetLanguageGeo: 'ინგლისური' });
                break;
            case 'ru':
                this.setState({ targetLanguageGeo: 'რუსული' });
                break;
            case 'tr':
                this.setState({ targetLanguageGeo: 'თურქული' });
                break;
            case 'es':
                this.setState({ targetLanguageGeo: 'ესპანური' });
                break;
            case 'fr':
                this.setState({ targetLanguageGeo: 'ფრანგული' });
                break;
            case 'de':
                this.setState({ targetLanguageGeo: 'გერმანული' });
                break;
            case 'ua':
                this.setState({ targetLanguageGeo: 'უკრაინული' });
                break;
            case 'zh':
                this.setState({ targetLanguageGeo: 'ჩინური' });
                break;
            case 'pt':
                this.setState({ targetLanguageGeo: 'პორტუგალიური' });
                break;
            default:
                ;
        }
    };

    setDocType = async (docType) => {


        const docTypes = {
            "1": "სტანდარტული",
            "2": "იურიდიული",
            "3": "ტექნიკური",
            "4": "სამედიცინო",
            "5": "მინდობილობა",
            "6": "თანხმობა",
            "7": "ამონაწერი",
            "8": "მოწმობა/ცნობა",
            "9": "სამედიცინო ფორმები"
        };


        await this.setState({ documentType: docType });
        await this.setState({ documentTypeName: docTypes[docType] });
        await this.calculatePrice();
    };

    closeErrorModalHandler = () => {
        this.setState({ showErrorModal: false });
        this.clearState();
    };
    showErrorModalHandler = () => {
        this.setState({ showErrorModal: true });
    };
    additionalServiceHandler = async () => {
        if (this.state.additionalService) {
            await this.setState({ courierService: false });
        }
        await this.setState({ additionalService: !this.state.additionalService });
        await this.calculatePrice();
    };
    /*TODO courier service ex*/
    courierServiceHandler = async () => {
        if (this.state.additionalService && (this.getTimeByHour() === 1 || this.state.passingDate === 'slow')) {
            await this.setState({ courierService: !this.state.courierService });
            await this.calculatePrice();
        }
    };

    enableNotaryHandler = (boolean) => {
        this.setState({ additionalService: boolean });
    };


    passingDateHandler = async e => {
        const passingParams = {
            "slow": "სდანტარტული",
            "fast": "დაჩქარებული"
        };
        this.setState({ passingDate: e.target.name });
        this.setState({ passingDateName: passingParams[e.target.name] });
    };

    clearInsertedText = () => {
        this.setState({
            finalInsertedText: ''
        });
    };

    calculateTime = () => {
        let pages = this.state.response.analyzeData.normalizedPages;
        if (this.state.passingDate === 'slow' && (pages < 5 && pages > 0)) {
            this.setState({ orderTime: '1დღე' });
        }
        if (this.state.passingDate === 'slow' && (pages < 10 && pages >= 5)) {
            this.setState({ orderTime: '1.5დღე' });
        }
        if (this.state.passingDate === 'slow' && (pages < 15 && pages >= 10)) {
            this.setState({ orderTime: '2დღე' });
        }
        if (this.state.passingDate === 'slow' && (pages < 20 && pages >= 15)) {
            this.setState({ orderTime: '2.5დღე' });
        }
        if (this.state.passingDate === 'slow' && (pages < 25 && pages >= 20)) {
            this.setState({ orderTime: '3დღე' });
        }
        if (this.state.passingDate === 'slow' && (pages < 30 && pages >= 25)) {
            this.setState({ orderTime: '3.5დღე' });
        }
        if (this.state.passingDate === 'slow' && (pages < 35 && pages >= 30)) {
            this.setState({ orderTime: '4დღე' });
        }
        if (this.state.passingDate === 'slow' && (pages < 40 && pages >= 35)) {
            this.setState({ orderTime: '4.5დღე' });
        }
        if (this.state.passingDate === 'slow' && (pages < 45 && pages >= 40)) {
            this.setState({ orderTime: '5დღე' });
        }
        if (this.state.passingDate === 'slow' && (pages >= 45)) {
            this.setState({ orderTime: '5.5დღე' });
        }
        // end of standart

        if (this.state.passingDate === 'fast' && (pages < 5 && pages > 0)) {
            this.setState({ orderTime: '3სთ' });
        }
        if (this.state.passingDate === 'fast' && (pages < 10 && pages >= 5)) {
            this.setState({ orderTime: '5სთ' });
        }
        if (this.state.passingDate === 'fast' && (pages < 15 && pages >= 10)) {
            this.setState({ orderTime: '7სთ' });
        }
        if (this.state.passingDate === 'fast' && (pages < 20 && pages >= 15)) {
            this.setState({ orderTime: '10სთ' });
        }
        if (this.state.passingDate === 'fast' && (pages < 25 && pages >= 20)) {
            this.setState({ orderTime: '12სთ' });
        }
        if (this.state.passingDate === 'fast' && (pages < 30 && pages >= 25)) {
            this.setState({ orderTime: '1დღე' });
        }
        if (this.state.passingDate === 'fast' && (pages < 35 && pages >= 30)) {
            this.setState({ orderTime: '1.5დღე' });
        }
        if (this.state.passingDate === 'fast' && (pages < 40 && pages >= 35)) {
            this.setState({ orderTime: '2დღე' });
        }
        if (this.state.passingDate === 'fast' && (pages < 45 && pages >= 40)) {
            this.setState({ orderTime: '2.5დღე' });
        }
        if (this.state.passingDate === 'fast' && (pages >= 45)) {
            this.setState({ orderTime: '3დღე' });
        }


    };

    disableContinueBtnHandler = () => {
        this.setState({ disableContinueBtn: true });
    };
    enableContinueBtnHandler = () => {
        this.setState({ disableContinueBtn: false });
    };

    nameChangeHandler = e => {
        this.setState({ name: e.target.value });
    };

    emailChangeHandler = e => {
        this.setState({ email: e.target.value });
    };
    phoneChangeHandler = e => {
        this.setState({ phone: e.target.value });
    };

    vertificationCodeHandler = e => {
        this.setState({ userVertificationCode: parseInt(e.target.value) });
    };

    addressChangeHandler = e => {
        this.setState({ address: e.target.value });
    };

    additionalAddressChangeHandler = e => {
        this.setState({ additionalAddress: e.target.value });
    };
    cityChangeHandler = e => {
        this.setState({ city: e.target.value });
    };

    resetPersonalInfoHandler = () => {
        this.setState({
            address: null,
            additionalAddress: null,
            city: null,
            email: null,
            phone: null,
            name: null
        });
    };

    commentHandler = e => {
        this.setState({ comment: e.target.value });
    };


    setProject = (_project) => {
        this.setState({ project: _project });
        this.setState({ totalPrice: _project.totalPrice });
    };

    totalPriceHandler = async (_price) => {
        // console.log('SET TOTAL PRICE IN PROVIDER :::: ', _price);
        await this.setState({ totalPrice: _price });
    };

    setPageCount = (_page) => {
        this.setState({ pageCount: _page.toFixed(2) });
    };


    sendPhoneNumber = async (phoneIsNullCallback) => {
        const apiUri = `${API_URL}/sms`;
        const phone = {
            phoneNumber: this.state.phone
        };
        if (this.state.phone === null) {
            console.error('NO PHONE NUMBER');
            return;
        }
        let vertificationCodeData = await axios.post(apiUri, phone);
        this.setState({ recievedVertificationCode: vertificationCodeData.data.code });

    };

    getTimeByHour = () => {
        const hour = new Date().getHours();
        //before 1PM
        if (hour <= 12) {
            return 1;
            //between 1PM and 3PM
        } else if (hour >= 13 && hour <= 14) {
            return 2;
            //after 3PM
        } else if (hour >= 15) {
            return 3;
        }
    };


    setJwtToken = async (token) => {
        // console.log("Set JWT token test ....")
        this.setState({ jwtToken: token });
    };

    logout = () => {
        this.setState({ jwtToken: null });
        this.setState({ isLoggedIn: false });
    };


    getPosts = async () => {
        const apiUri = `${API_URL}/api/blog`;
        const { data } = await axios.get(apiUri);
        this.setState({ blogPosts: data.posts });
        // console.log(data);
    };

    async componentDidMount() {
        window.onpopstate = () => {
            this.clearState();

        };
    }

    render() {
        const handlers = {
            scrollToTop: this.scrollToTop,
            disableOrderCardsHandler: this.disableOrderCardsHandler,
            enableOrderCardsHandler: this.enableOrderCardsHandler,
            disableDocumentTypeHandler: this.disableDocumentTypeHandler,
            disableAdditionalServicesHandler: this.disableAdditionalServicesHandler,
            disablePassingDateHandler: this.disablePassingDateHandler,
            clearState: this.clearState,
            clearStateOnOrderPage: this.clearStateOnOrderPage,
            clearFile: this.clearFile,
            closeErrorModalHandler: this.closeErrorModalHandler,
            clearInsertedText: this.clearInsertedText,
            showErrorModalHandler: this.showErrorModalHandler,
            setResponse: this.setResponse,
            setJwtToken: this.setJwtToken,
            setProject: this.setProject,
            setDisableTextarea: this.setDisableTextarea,
            setInsertedText: this.setInsertedText,
            saveInsertedText: this.saveInsertedText,
            setBaseLang: this.setBaseLang,
            setTargetLang: this.setTargetLang,
            setBaseLangGeo: this.setBaseLangGeo,
            setTargetLangGeo: this.setTargetLangGeo,
            setDocType: this.setDocType,
            setfileToTranslate: this.setfileToTranslate,
            setNotaryFileToTranslate: this.setNotaryFileToTranslate,
            additionalServiceHandler: this.additionalServiceHandler,
            courierServiceHandler: this.courierServiceHandler,
            getTimeByHour: this.getTimeByHour,
            enableNotaryHandler: this.enableNotaryHandler,
            disableContinueBtnHandler: this.disableContinueBtnHandler,
            enableContinueBtnHandler: this.enableContinueBtnHandler,
            calculateTime: this.calculateTime,
            setPageCount: this.setPageCount,
            passingDateHandler: this.passingDateHandler,
            nameChangeHandler: this.nameChangeHandler,
            emailChangeHandler: this.emailChangeHandler,
            phoneChangeHandler: this.phoneChangeHandler,
            vertificationCodeHandler: this.vertificationCodeHandler,
            addressChangeHandler: this.addressChangeHandler,
            additionalAddressChangeHandler: this.additionalAddressChangeHandler,
            cityChangeHandler: this.cityChangeHandler,
            resetPersonalInfoHandler: this.resetPersonalInfoHandler,
            setFormData: this.setFormData,
            calculatePrice: this.calculatePrice,
            totalPriceHandler: this.totalPriceHandler,
            sendPhoneNumber: this.sendPhoneNumber,
            setIsProcessing: this.setIsProcessing,
            commentHandler: this.commentHandler,
            calculateNotaryPrice: this.calculateNotaryPrice,
            getPosts: this.getPosts,
        };
        return (
            <Context.Provider value={{ globalstate: this.state, handlers: handlers }}>
                {this.props.children}
            </Context.Provider>
        );
    }
}

export default Provider;
