import React, { useState, useEffect, useRef, useContext } from "react";
import { Link } from "react-router-dom";
import { Card, Button, ButtonToolbar, Tooltip, OverlayTrigger, Badge, ListGroupItem } from "react-bootstrap";
import Context from "../../context/Context";
import ListGroup from "react-bootstrap/ListGroup";
import { FaLongArrowAltRight, FaRedo } from "react-icons/fa";
import { useToasts } from "react-toast-notifications";
import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

const OrderInfo = props => {
    const [ btnOrderDisabled, setBtnOrderDisabled ] = useState(false);
    const [ transactionId, setTransactionId ] = useState("");
    const context = useContext(Context);

    /*eslint-disable */
    const {
        calculateTime, setPageCount, disableContinueBtnHandler, enableContinueBtnHandler,
        clearStateOnOrderPage,
        setDisableTextarea
    } = context.handlers;
    const {
        response, finalInsertedText, passingDateName, passingDate, pageCount,
        orderTime, baseLanguageGeo, targetLanguageGeo, fileToTranslate,
        notaryFileToTranslate, userVertificationCode, recievedVertificationCode,
        projectuid, disableContinueBtn, totalPrice, city
    } = context.globalstate;
    /*eslint-disable */

    const termCheckboxRef = useRef(null);

    const { scrollToTop } = context.handlers;

    const tbcform = useRef(null);

    const { addToast } = useToasts();

    useEffect(() => {
        // console.log("resposne ::: ", response);
        if ((response && response.projectuid) || finalInsertedText !== "") {
            enableContinueBtnHandler();
            setPageCount(response.pageCount);
            calculateTime();
        }
    }, [ response ]);

    const clearCheckbox = () => {
        props.checkboxRef.current.checked = false;
        props.checkboxCourier.current.checked = false;
    };

    const orderTranslation = async () => {
        disableContinueBtnHandler();

        // console.log("Order Translation ... ");
        let { response: project } = context.globalstate;

        // console.log("Register project : Payment ");
        // console.log(" Response : ", project);


        const {
            name,
            email,
            phone,
            address,
            additionalAddress,
            city,
            comment,
            additionalService,
            courierService
        } = context.globalstate;

        project.name = name;
        project.email = email;
        project.phone = phone;
        project.address = address;
        project.additionalAddress = additionalAddress;
        project.city = city;
        project.courierService = courierService;
        project.comment = comment;

        if (name && email) {
            setBtnOrderDisabled(false);
        }

        if (!name || !email || !phone) {
            addToast(" გთხოვთ შეავსოთ საკონტაქტო ინფორმაცია  ", {
                appearance: "error",
                autoDismiss: false
            });

            setBtnOrderDisabled(false);
            return;
        }

        if (additionalService && !address) {
            addToast(" გთხოვთ შეიყვანოთ თქვენი მისამართი ", {
                appearance: "error",
                autoDismiss: false
            });

            setBtnOrderDisabled(false);
            return;
        }
        if (!termCheckboxRef.current.checked) {
            addToast(" გთხოვთ, გაეცნოთ და დაეთანხმოთ საიტის წესებსა და პირობებს", {
                appearance: "error",
                autoDismiss: true
            });
            return;
        }

        // console.log(" Project : ", project);
        try {
            const apiUri = `${API_URL}/api/payment/register`;
            setBtnOrderDisabled(true);
            const registerTransactionResponse = await axios.post(apiUri, project);
            // console.log("Register Reponse :  ", registerTransactionResponse);
            setTransactionId(registerTransactionResponse.data.transaction_id);
            tbcform.current.submit();
        } catch (error) {
            // console.log(error);
            setBtnOrderDisabled(false);
        }

    };

    let style = {
        maxWidth: "336px",
        minWidth: "21rem"
    };

    let formStyle = {
        display: "block"
    };

    return (
        <div>
            <Card
                style={style}
                className={
                    window.location.pathname === "/order-summary"
                        ? "info-card-summary"
                        : "info-card"
                }
            >
                <Card.Header className="info-card-header">ინფორმაცია</Card.Header>
                <ListGroup variant="flush" className="info-card-list">
                    <ListGroup.Item>
                        <ul>
                            <div className={props.spinner === true ? "info-card-items" : ""}>
                                <li>
                                    ფაილი:{" "}
                                    {fileToTranslate && (
                                        <OverlayTrigger
                                            key={"top"}
                                            placement={"top"}
                                            overlay={
                                                <Tooltip id={`tooltip-top`}>
                                                    ფაილის სახელი : <strong>{fileToTranslate}</strong>.
                                                </Tooltip>
                                            }
                                        >
                                            <Badge
                                                className="uploaded-file-name"
                                                pill
                                                variant="light"
                                            >
                                                {fileToTranslate}
                                            </Badge>
                                        </OverlayTrigger>
                                    )}{" "}
                                    {notaryFileToTranslate && (
                                        <OverlayTrigger
                                            key={"top"}
                                            placement={"top"}
                                            overlay={
                                                <Tooltip id={`tooltip-top`}>
                                                    ფაილის სახელი{" "}
                                                    {notaryFileToTranslate.map((fileName, index) => (
                                                        <strong
                                                            key={fileName + index}>{index !== notaryFileToTranslate.length - 1 ? fileName + ',' : fileName}</strong>
                                                    ))}
                                                </Tooltip>
                                            }
                                        >
                                            <Badge
                                                className="uploaded-file-name"
                                                pill
                                                variant="light"
                                            >
                                                {notaryFileToTranslate.map((fileName, index) => (
                                                    <strong
                                                        key={fileName + index}>{index !== notaryFileToTranslate.length - 1 ? fileName + ',' : fileName}</strong>
                                                ))}
                                            </Badge>
                                        </OverlayTrigger>
                                    )}{" "}
                                </li>
                                <li>
                                    გვერდების რაოდენობა <span>{pageCount ? pageCount : " "}</span>
                                </li>
                                <li>
                                    თარგმანი
                                    <span>
                    {baseLanguageGeo === "" || targetLanguageGeo === ""
                        ? null
                        : baseLanguageGeo +
                        "დან " +
                        targetLanguageGeo.slice(0, -1) +
                        "ზე"}
                  </span>
                                </li>
                                <li>
                                    დოკ. ტიპი <span>{context.globalstate.documentTypeName}</span>{" "}
                                </li>
                                <li>
                                    ნოტ.დამოწმება{" "}
                                    <span>
                    {context.globalstate.additionalService
                        ? "მსურს"
                        : "არ მსურს"}
                  </span>{" "}
                                </li>
                                <li>
                                    საკურიერო მომსახურება{" "}
                                    <span>
                    {context.globalstate.courierService
                        ? city === 'თბილისი' || !city ? "2 სამუშაო დღე - 10₾" : '6 სამუშაო დღე - 10₾'
                        : "არ მსურს"}
                  </span>{" "}
                                </li>
                                {/* <li>
                                    მიწოდების დრო{" "}
                                    <span>
                    {passingDateName}&nbsp;{orderTime}
                  </span>{" "}
                                </li> */}
                                {window.location.pathname === "/order-summary" ? (
                                    <div>
                                        <li>
                                            სახელი{" "}
                                            <span>
                        {context.globalstate.name === ""
                            ? "-"
                            : context.globalstate.name}
                      </span>{" "}
                                        </li>
                                        <li>
                                            ტელ{" "}
                                            <span>
                        {context.globalstate.phone === ""
                            ? "-"
                            : context.globalstate.phone}
                      </span>{" "}
                                        </li>
                                        <li>
                                            ფოსტა{" "}
                                            <span>
                        {context.globalstate.email === ""
                            ? "-"
                            : context.globalstate.email}
                      </span>{" "}
                                        </li>
                                        {context.globalstate.additionalService ? (
                                            <div>
                                                <li>
                                                    მისამართი{" "}
                                                    <span>
                          {context.globalstate.address === ""
                              ? "-"
                              : context.globalstate.address}
                        </span>{" "}
                                                </li>
                                                <li>
                                                    მისამართი (დამატებითი ინფორმაცია){" "}
                                                    <span>
                          {context.globalstate.additionalAddress === ""
                              ? "-"
                              : context.globalstate.additionalAddress}
                        </span>{" "}
                                                </li>
                                            </div>
                                        ) : null}
                                        {context.globalstate.courierService ? (
                                            <li>
                                                ქალაქი{" "}
                                                <span>
                          {context.globalstate.city === ""
                              ? "-"
                              : context.globalstate.city}
                        </span>{" "}
                                            </li>
                                        ) : null}
                                        <li className="terms-li-item">
                                            {" "}
                                            ვეთანხმები{" "}
                                            <Link to="/terms" target="_blank">
                                                წესებს და პირობებს
                                            </Link>{" "}
                                            <input
                                                type="checkbox"
                                                ref={termCheckboxRef}
                                                className="term-checkbox"
                                            />
                                        </li>
                                    </div>
                                ) : null}
                            </div>
                        </ul>
                    </ListGroup.Item>
                    <ListGroup.Item>
                        ფასი{" "}
                        <span className="order-price">
              {response ? Math.round((response.totalPrice + Number.EPSILON) * 100) / 100 : 0 || totalPrice} ₾


            </span>
                    </ListGroup.Item>
                    
                    <ListGroup.Item>
                        <span style={{'font-size': '0.8rem','position': 'initial'}}>შაბათ-კვირას არასამუშაო საათებია. ამ დღეებში შემოსული შეკვეთის დამუშავება დაიწყება ორშაბათიდან</span>
                    </ListGroup.Item>

                    <Card.Footer>
                        {" "}
                        <div className="justify-content-center">
                            {window.location.pathname === "/order-summary" ? (
                                <ButtonToolbar>
                                    <Button
                                        disabled={btnOrderDisabled}
                                        onClick={orderTranslation}
                                        variant="outline-primary"
                                    >
                                        შეკვეთა
                                    </Button>
                                </ButtonToolbar>
                            ) : (
                                <ButtonToolbar>
                                    {projectuid || notaryFileToTranslate ? (
                                        <Button
                                            onClick={() => {
                                                clearStateOnOrderPage();
                                                scrollToTop();
                                                clearCheckbox();
                                                setDisableTextarea(false);
                                            }}
                                            className=" btn-red btn-start-again"
                                        >
                                            თავიდან დაწყება <FaRedo/>
                                        </Button>
                                    ) : null}
                                    <Link to="/order-summary" onClick={scrollToTop}>
                                        {"   "}
                                        <Button
                                            disabled={disableContinueBtn}
                                            className=" btn-blue btn-continue"
                                        >
                                            გაგრძელება <FaLongArrowAltRight/>
                                            {disableContinueBtn ? null : (
                                                <div className="pulse-circle"></div>
                                            )}
                                        </Button>
                                    </Link>
                                </ButtonToolbar>
                            )}
                        </div>
                    </Card.Footer>
                </ListGroup>
            </Card>

            <form
                ref={tbcform}
                style={formStyle}
                action="https://ecommerce.ufc.ge/ecomm2/ClientHandler"
                method="POST"
            >
                <input type="hidden" name="trans_id" value={transactionId}/>
            </form>
        </div>
    );
};

export default OrderInfo;
