import React, { useContext, useState } from "react";
import Context from "../../../context/Context";
import OrderCard from "../OrderCard";
import Information from "../Information";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Collapse from 'react-bootstrap/Collapse';
import { useToasts } from "react-toast-notifications";


const CourierService = props => {


    const context = useContext(Context);
    const { courierServiceHandler, getTimeByHour } = context.handlers;
    let { courierService, additionalService, city, passingDate } = context.globalstate;
    const { addToast } = useToasts();


    const controlCourierCheckbox = () => {
        props.checkboxCourier.current.checked = !props.checkboxCourier.current.checked;
        if (courierService === true) {
            props.checkboxCourier.current.checked = false;
        }
        if (additionalService === false) {
            props.checkboxCourier.current.checked = false;
        }
        if (getTimeByHour() !== 1 && passingDate === 'fast') {
            props.checkboxCourier.current.checked = false;
        }
    };

    const courierError = () => {
        if (additionalService === false) {
            addToast("საკურიერო მომსახურება ვრცელდება მხოლოდ ნოტარიულად დამოწმებულ დოკუმენტზე", {
                appearance: "error",
                autoDismiss: true
            });
        } else if (getTimeByHour() !== 1 && passingDate === 'fast') {
            addToast("საკურიერო მომსახურება დაჩქარებული სერვისის დროს ხელმისაწვდომია 13 საათამდე", {
                appearance: "error",
                autoDismiss: true
            });
        }
    };

    return (
        <Row>
            <Col>
                <fieldset disabled={props.disabledForm}>
                    {/* <fieldset> */}
                    <OrderCard title="საკურიერო მომსახურების სერვისი" number="4">
                        <Information>
                            სტანდარტული მომსახურების შემთხვევაში კურიერი თქვენს დოკუმენტს მოგაწვდით თარგმანის
                            დასრულებიდან მეორე დღეს.
                            დაჩქარებულის შემთხვევაში იმავე დღეს.
                            დაჩქარებული მომსახურების მისაღებად გთხოვთ დოკუმენტი ატვირთოთ დღის 13:00 საათამდე.
                        </Information>
                        <div>
                            {/*courier service available only when additional service is selected*/}
                            <input
                                disabled={additionalService === false || (passingDate === 'fast' && getTimeByHour() !== 1)}
                                type="checkbox"
                                ref={props.checkboxCourier}
                                aria-controls="collapse-cities"
                                aria-expanded={courierService}
                                onClick={() => {
                                    // setOpen(!open);
                                    courierServiceHandler();
                                    courierError();
                                }}
                            />{" "}
                            <span className="courier"
                                  aria-controls="example-collapse-text"
                                  aria-expanded={courierService}
                                  onClick={() => {
                                      courierServiceHandler();
                                      controlCourierCheckbox();
                                      courierError();
                                      // setOpen(!open);
                                  }}
                            >მსურს დოკუმენტი მომიტანოს კურიერმა</span>

                            <Collapse in={courierService}>
                                <div id="collapse-cities">
                                    <select
                                        onChange={context.handlers.cityChangeHandler}
                                        className="city-select mx-auto my-2"
                                    >
                                        <option defaultValue value="">
                                            აირჩიეთ ქალაქი
                                        </option>
                                        <option value="თბილისი">
                                            თბილისი
                                        </option>
                                        <option value="წყნეთი">
                                            წყნეთი
                                        </option>
                                        <option value="კოჯორი">
                                            კოჯორი
                                        </option>
                                        <option value="კიკეთი">
                                            კიკეთი
                                        </option>
                                        <option value="გორი">
                                            გორი
                                        </option>
                                        <option value="ხაშური">
                                            ხაშური
                                        </option>
                                        <option value="ქუთაისი">
                                            ქუთაისი
                                        </option>
                                        <option value="ფოთი">
                                            ფოთი
                                        </option>
                                        <option value="ბათუმი">
                                            ბათუმი
                                        </option>
                                        <option value="ახალციხე">
                                            ახალციხე
                                        </option>
                                        <option value="რუსთავი">
                                            რუსთავი
                                        </option>
                                        <option value="თელავი">
                                            თელავი
                                        </option>
                                        <option value="გურჯაანი">
                                            გურჯაანი
                                        </option>
                                        <option value="ზუგდიდი">
                                            ზუგდიდი
                                        </option>
                                        <option value="სამტრედია">
                                            სამტრედია
                                        </option>
                                        <option value="ზესტაფონი">
                                            ზესტაფონი
                                        </option>
                                        <option value="სენაკი">
                                            სენაკი
                                        </option>
                                        <option value="აბაშა">
                                            აბაშა
                                        </option>
                                        <option value="ქობულეთი">
                                            ქობულეთი
                                        </option>
                                        <option value="მცხეთა">
                                            მცხეთა
                                        </option>
                                        <option value="ბორჯომი">
                                            ბორჯომი
                                        </option>
                                        <option value="დმანისი">
                                            დმანისი
                                        </option>
                                        <option value="ბოლნისი">
                                            ბოლნისი
                                        </option>
                                        <option value="მარნეული">
                                            მარნეული
                                        </option>
                                        <option value="გარდაბანი">
                                            გარდაბანი
                                        </option>
                                        <option value="ქსანი">
                                            ქსანი
                                        </option>
                                        <option value="სიღნაღი">
                                            სიღნაღი
                                        </option>
                                        <option value="საგარეჯო">
                                            საგარეჯო
                                        </option>
                                        <option value="ლაგოდეხი">
                                            ლაგოდეხი
                                        </option>
                                        <option value="ლანჩხუთი">
                                            ლანჩხუთი
                                            ჩოხატაური
                                        </option>
                                        <option value="ყვარელი">
                                            ყვარელი
                                        </option>
                                        <option value="გონიო">
                                            გონიო
                                        </option>
                                        <option value="სარფი">
                                            სარფი
                                        </option>
                                        <option value="სოფელი ბარისახო">
                                            სოფელი ბარისახო
                                        </option>
                                        <option value="გრემისხევი">
                                            გრემისხევი
                                        </option>
                                        <option value="შუაფხო">
                                            შუაფხო
                                        </option>
                                        <option value="არხოტი">
                                            არხოტი
                                        </option>
                                        <option value="შატილი">
                                            შატილი
                                        </option>
                                        <option value="სოფელი ვახანი">
                                            სოფელი ვახანი
                                        </option>
                                        <option value="სოფელი ხემაღალი">
                                            სოფელი ხემაღალი
                                        </option>
                                        <option value="სოფელი შქმერი">
                                            სოფელი შქმერი
                                        </option>
                                        <option value="სოფელი ხეითი">
                                            სოფელი ხეითი
                                        </option>
                                        <option value="სოფელი ზვარეთი">
                                            სოფელი ზვარეთი
                                        </option>
                                        <option value="სოფელი გამოჩინებული">
                                            სოფელი გამოჩინებული
                                        </option>
                                        <option value="სოფელი ორმეთი">
                                            სოფელი ორმეთი
                                        </option>
                                        <option value="სოფელი წიაღუბანი">
                                            სოფელი წიაღუბანი
                                        </option>
                                        <option value="სოფელი იორამა">
                                            სოფელი იორამა
                                        </option>
                                        <option value="სოფელი ოფეთი">
                                            სოფელი ოფეთი
                                        </option>
                                        <option value="სოფელი რცხილათი">
                                            სოფელი რცხილათი
                                        </option>
                                        <option value="სოფელი რცხილათი">
                                            სოფელი რცხილათი
                                        </option>
                                        <option value="სოფელი სალიეთი">
                                            სოფელი სალიეთი
                                        </option>
                                        <option value="სოფელი ბიჯა">
                                            სოფელი ბიჯა
                                        </option>
                                        <option value="სოფელი ნასპერი">
                                            სოფელი ნასპერი
                                        </option>
                                        <option value="სოფელი ღვირიში">
                                            სოფელი ღვირიში
                                        </option>
                                        <option value="სოფელი კარი">
                                            სოფელი კარი
                                        </option>
                                        <option value="სოფელი ჩაჟამი">
                                            სოფელი ჩაჟამი
                                        </option>
                                        <option value="სოფელი მულახი">
                                            სოფელი მულახი
                                        </option>
                                        <option value="სოფელი კალა">
                                            სოფელი კალა
                                        </option>
                                        <option value="სოფელი იელი">
                                            სოფელი იელი
                                        </option>
                                        <option value="სოფელი წვირმი">
                                            სოფელი წვირმი
                                        </option>
                                        <option value="სოფელი უშგული">
                                            სოფელი უშგული
                                        </option>
                                        <option value="სოფელი იფარი">
                                            სოფელი იფარი
                                        </option>
                                        <option value="სოფელი შაბეში">
                                            სოფელი შაბეში
                                        </option>
                                        <option value="სოფელი ადიში">
                                            სოფელი ადიში
                                        </option>
                                        <option value="სოფელი ივლი">
                                            სოფელი ივლი
                                        </option>
                                        <option value="სოფელი ცხუმარი">
                                            სოფელი ცხუმარი
                                        </option>
                                        <option value="სოფელი ჩრდილი">
                                            სოფელი ჩრდილი
                                        </option>
                                        <option value="სოფელი მოლითი">
                                            სოფელი მოლითი
                                        </option>
                                        <option value="სოფელი ზვარე">
                                            სოფელი ზვარე
                                        </option>
                                        <option value="სოფელი უჩანეთი">
                                            სოფელი უჩანეთი
                                        </option>
                                        <option value="სოფელი წიფა">
                                            სოფელი წიფა
                                        </option>
                                        <option value="სოფელი მარელისი">
                                            სოფელი მარელისი
                                        </option>
                                        <option value="მოსაბრუნი">
                                            მოსაბრუნი
                                        </option>
                                        <option value="სოფელი ფიროსმანი">
                                            სოფელი ფიროსმანი
                                        </option>
                                        <option value="საბათლო">
                                            საბათლო
                                        </option>
                                        <option value="სამთაწყარო">
                                            სამთაწყარო
                                        </option>
                                        <option value="კასრისწყალი">
                                            კასრისწყალი
                                        </option>
                                        <option value="არხელოსკალო">
                                            არხელოსკალო
                                        </option>
                                        <option value="ერისიმედი">
                                            ერისიმედი
                                        </option>
                                        <option value="ტაკიძეები">
                                            ტაკიძეები
                                        </option>
                                        <option value="მეძიბნა">
                                            მეძიბნა
                                        </option>
                                        <option value="ზესოფელი">
                                            ზესოფელი
                                        </option>
                                        <option value="ზენდიდი">
                                            ზენდიდი
                                        </option>
                                        <option value="კუჭულა">
                                            კუჭულა
                                        </option>
                                        <option value="შევაბური">
                                            შევაბური
                                        </option>
                                        <option value="სოფელი თეჯისი">
                                            სოფელი თეჯისი
                                        </option>
                                        <option value="სოფელი ხანდო">
                                            სოფელი ხანდო
                                        </option>
                                        <option value="სოფელი ბაშკოვი">
                                            სოფელი ბაშკოვი
                                        </option>
                                        <option value="ხულდარა">
                                            ხულდარა
                                        </option>
                                        <option value="სოფელი ტაბაწყური (სამცხე-ჯავახეთი)">
                                            სოფელი ტაბაწყური (სამცხე-ჯავახეთი)
                                        </option>
                                        <option value="სოფელი ოფრეთი">
                                            სოფელი ოფრეთი
                                        </option>
                                        <option value="ქიზილქილისა">
                                            ქიზილქილისა
                                        </option>
                                        <option value="არგრევანი">
                                            არგრევანი
                                        </option>
                                        <option value="ამამლო">
                                            ამამლო
                                        </option>
                                        <option value="იარგანჩლო">
                                            იარგანჩლო
                                        </option>
                                        <option value="ბედიანი">
                                            ბედიანი
                                        </option>
                                        <option value="ხაჩკორი">
                                            ხაჩკორი
                                        </option>
                                        <option value="კაბური">
                                            კაბური
                                        </option>
                                        <option value="საბეჭისი">
                                            საბეჭისი
                                        </option>
                                        <option value="თეჯისი">
                                            თეჯისი
                                        </option>
                                        <option value="ახალშენი">
                                            ახალშენი
                                        </option>
                                        <option value="შუბანი">
                                            შუბანი
                                        </option>
                                        <option value="დარჩიძეები">
                                            დარჩიძეები
                                        </option>
                                        <option value="კობალთა">
                                            კობალთა
                                        </option>
                                        <option value="ერდვათი">
                                            ერდვათი
                                        </option>
                                        <option value="მახალაკიძეები">
                                            მახალაკიძეები
                                        </option>
                                        <option value="პაპოშვილები">
                                            პაპოშვილები
                                        </option>
                                        <option value="ოლადაური">
                                            ოლადაური
                                        </option>
                                        <option value="წელათი">
                                            წელათი
                                        </option>
                                        <option value="მაწყვალთა">
                                            მაწყვალთა
                                        </option>
                                        <option value="კარაპეტი">
                                            კარაპეტი
                                        </option>
                                        <option value="ჭალა">
                                            ჭალა
                                        </option>
                                        <option value="წყაროთა">
                                            წყაროთა
                                        </option>
                                        <option value="ვანი">
                                            ვანი
                                        </option>
                                        <option value="ზემოხევი">
                                            ზემოხევი
                                        </option>
                                        <option value="ნაღვარევი">
                                            ნაღვარევი
                                        </option>
                                        <option value="ჟანივრი">
                                            ჟანივრი
                                        </option>
                                        <option value="წყალსაყარი">
                                            წყალსაყარი
                                        </option>
                                        <option value="ინწკირვეთი">
                                            ინწკირვეთი
                                        </option>
                                        <option value="ცეკვა">
                                            ცეკვა
                                        </option>
                                        <option value="კვიახიძეები">
                                            კვიახიძეები
                                        </option>
                                        <option value="პაქსაძეები">
                                            პაქსაძეები
                                        </option>
                                        <option value="გელაძეები">
                                            გელაძეები
                                        </option>
                                        <option value="კორტოხი">
                                            კორტოხი
                                        </option>
                                        <option value="დიოკნისი">
                                            დიოკნისი
                                        </option>
                                        <option value="მანიაკეთი">
                                            მანიაკეთი
                                        </option>
                                        <option value="ბეღლრთი">
                                            ბეღლრთი
                                        </option>
                                        <option value="ტაბახმელა">
                                            ტაბახმელა
                                        </option>
                                        <option value="ბოძაური">
                                            ბოძაური
                                        </option>
                                        <option value="რიყეთი">
                                            რიყეთი
                                        </option>
                                        <option value="შუასოფელი">
                                            შუასოფელი
                                        </option>
                                        <option value="დანისპარაული">
                                            დანისპარაული
                                        </option>
                                        <option value="ბეშუმი">
                                            ბეშუმი
                                        </option>
                                        <option value="გუდასახო">
                                            გუდასახო
                                        </option>
                                        <option value="ფაჩხა">
                                            ფაჩხა
                                        </option>
                                        <option value="მახალაკური">
                                            მახალაკური
                                        </option>
                                        <option value="ოშანახევი">
                                            ოშანახევი
                                        </option>
                                        <option value="ვერნები">
                                            ვერნები
                                        </option>
                                        <option value="სკვანა">
                                            სკვანა
                                        </option>
                                        <option value="თხილვანა">
                                            თხილვანა
                                        </option>
                                        <option value="მთისუბანი">
                                            მთისუბანი
                                        </option>
                                        <option value="ხიხაძირი">
                                            ხიხაძირი
                                        </option>
                                        <option value="ბაკო">
                                            ბაკო
                                        </option>
                                        <option value="კალოთა">
                                            კალოთა
                                        </option>
                                        <option value="ფუშრუკაული">
                                            ფუშრუკაული
                                        </option>
                                        <option value="ძმაგულა">
                                            ძმაგულა
                                        </option>
                                        <option value="რაქვთა">
                                            რაქვთა
                                        </option>
                                        <option value="წაბლანა">
                                            წაბლანა
                                        </option>
                                        <option value="თაგო">
                                            თაგო
                                        </option>
                                        <option value="შურმული">
                                            შურმული
                                        </option>
                                        <option value="ღორჯომის ხეობა">
                                            ღორჯომის ხეობა
                                        </option>
                                        <option value="ღურტა">
                                            ღურტა
                                        </option>
                                        <option value="ხალა">
                                            ხალა
                                        </option>
                                        <option value="გორგაძეები">
                                            გორგაძეები
                                        </option>
                                        <option value="კოხი">
                                            კოხი
                                        </option>
                                        <option value="ქვედადაზედასამება">
                                            ქვედადაზედასამება
                                        </option>
                                        <option value="კონდითი">
                                            კონდითი
                                        </option>
                                        <option value="ქვედადაზედააჭყვა">
                                            ქვედადაზედააჭყვა
                                        </option>
                                        <option value="ხეყრუ">
                                            ხეყრუ
                                        </option>
                                        <option value="ჯიბინაური">
                                            ჯიბინაური
                                        </option>
                                        <option value="აჭი">
                                            აჭი
                                        </option>
                                        <option value="ქაქუთი">
                                            ქაქუთი
                                        </option>
                                        <option value="ჩაისუბანი">
                                            ჩაისუბანი
                                        </option>
                                        <option value="კირნათი">
                                            კირნათი
                                        </option>
                                        <option value="მარადიდი">
                                            მარადიდი
                                        </option>
                                        <option value="აგარა">
                                            აგარა
                                        </option>
                                        <option value="კაპრეშუმი">
                                            კაპრეშუმი
                                        </option>
                                        <option value="ყოროლისთავი">
                                            ყოროლისთავი
                                        </option>
                                        <option value="ჯიხანჯური">
                                            ჯიხანჯური
                                        </option>
                                        <option value="დაგვა">
                                            დაგვა
                                        </option>
                                        <option value="ზენითი">
                                            ზენითი
                                        </option>
                                        <option value="ლეღვა">
                                            ლეღვა
                                        </option>
                                        <option value="ჭახათი">
                                            ჭახათი
                                        </option>
                                        <option value="სამსარი">
                                            სამსარი
                                        </option>
                                        <option value="ეშტია">
                                            ეშტია
                                        </option>
                                        <option value="თახჩა">
                                            თახჩა
                                        </option>
                                        <option value="სამება">
                                            სამება
                                        </option>
                                        <option value="პატარა გონდურა">
                                            პატარა გონდურა
                                        </option>
                                        <option value="ანდეზიტი">
                                            ანდეზიტი
                                        </option>
                                        <option value="ციხისძირი">
                                            ციხისძირი
                                        </option>
                                        <option value="სარმანიშვილის კარი">
                                            სარმანიშვილის კარი
                                        </option>
                                        <option value="სათივე">
                                            სათივე
                                        </option>
                                        <option value="მონასტერი">
                                            მონასტერი
                                        </option>
                                        <option value="ცოცხნარა">
                                            ცოცხნარა
                                        </option>
                                        <option value="ქემფერი">
                                            ქემფერი
                                        </option>
                                        <option value="წაღვლი">
                                            წაღვლი
                                        </option>
                                        <option value="თაგვეთი">
                                            თაგვეთი
                                        </option>
                                        <option value="ბეშეთი">
                                            ბეშეთი
                                        </option>
                                        <option value="თკოცა">
                                            თკოცა
                                        </option>
                                        <option value="დერცელი">
                                            დერცელი
                                        </option>
                                        <option value="სვირი">
                                            სვირი
                                        </option>
                                        <option value="ბოგა">
                                            ბოგა
                                        </option>
                                        <option value="ტატანისი">
                                            ტატანისი
                                        </option>
                                        <option value="თისელი">
                                            თისელი
                                        </option>
                                        <option value="ალასტანი">
                                            ალასტანი
                                        </option>
                                        <option value="გოკიო">
                                            გოკიო
                                        </option>
                                        <option value="ხანდო">
                                            ხანდო
                                        </option>
                                        <option value="მერენია">
                                            მერენია
                                        </option>
                                        <option value="დადეში">
                                            დადეში
                                        </option>
                                        <option value="ჩუნჩხა">
                                            ჩუნჩხა
                                        </option>
                                        <option value="კასპი">
                                            კასპი
                                        </option>
                                    </select>
                                </div>
                            </Collapse>
                        </div>
                    </OrderCard>
                </fieldset>
            </Col>
        </Row>
    );
};

export default CourierService;
